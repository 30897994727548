import React, { Component } from "react"

import Modal from "react-modal"
import {
  AmountNumPicker,
  WhiteSquare,
  CommentsTextArea,
  OrangeButton,
} from "../../common"

import "./style.css"

class ServiceDetailsUpdate extends Component {
  constructor(props) {
    super(props)

    this.state = {
      item: null,
      moveToShowKeyboard: false,
    }
  }

  componentDidMount() {
    const { item } = this.props
    this.setState({ item })
  }

  componentWillReceiveProps(newProps) {
    const { item } = newProps

    this.setState({ item })
  }

  onAmountUpdate(newAmount) {
    const { item } = this.state

    this.setState({
      item: {
        ...item,
        amount: !newAmount || newAmount < 0 ? 1 : newAmount,
      },
    })
  }

  moveToShowKeyboard(isFocused) {
    this.setState({ moveToShowKeyboard: isFocused })
  }

  onCommentContentUpdate(content) {
    const { item } = this.state

    this.setState({
      item: {
        ...item,
        comment: !content ? "" : content,
      },
    })
  }

  onOkClick() {
    console.log("add item: ")
    console.log(this.state.item)
    this.props.onModalFinished(this.state.item)
  }

  onCancelClick() {
    this.props.onModalFinished(this.props.item)
    this.setState({ moveToShowKeyboard: false })
  }

  render() {
    const { show, translPackage, onModalFinished } = this.props

    if (!show) {
      return <noscript />
    }

    const { item, moveToShowKeyboard } = this.state
    let { icon, title, amount, comment } = item

    const {
      dirRtl,
      service_title,
      quantity_title,
      quantity_kg_title,
      comment_input_hint,
      ok_text,
      cancel_text,
      langName,
      put_to_bag,
    } = translPackage

    const wrapperClassName = `${dirRtl ? "dirrtl" : "dirltr"}`
    return (
      <Modal
        isOpen={show}
        onRequestClose={() => this.onCancelClick()}
        className={`react-modal-content order-details-modal ${
          moveToShowKeyboard ? "sdu-move-up" : ""
        }`}
        overlayClassName="modal-overlay"
      >
        <div className="service-modal-header">
          <div className="service-modal-title">{service_title}</div>
          <div className="service-modal-sub-title">{put_to_bag}</div>
        </div>
        <div className="service-and-quantity">
          <div className="quantity-frame">
            <div className="quantity-title">{quantity_title}</div>
            {(title === "כביסה" ||
              title === "Laundry" ||
              title === "Стирка") && (
              <div className="quantity-title">{quantity_kg_title}</div>
            )}

            <AmountNumPicker
              amount={amount}
              onAmountUpdate={(amount) => this.onAmountUpdate(amount)}
            />
          </div>
          <div className="service-icon-wrapper">
            <WhiteSquare
              icon={icon}
              title={title}
              item={item}
              extraClass="modal-service-icon"
            />
          </div>
        </div>
        <CommentsTextArea
          initVal={comment}
          placeHolderTransl={comment_input_hint}
          moveToShowKeyboard={(isFocused) => this.moveToShowKeyboard(isFocused)}
          onCommentContentUpdate={this.onCommentContentUpdate.bind(this)}
          refreshPosition={(isFocused) => moveToShowKeyboard(!isFocused)}
          langName={langName}
        />
        <div className={`serv-details-btns ${wrapperClassName}`}>
          <OrangeButton
            extraClass="serv-details-single-btn"
            onClick={() => this.onOkClick()}
          >
            {ok_text}
          </OrangeButton>
          <OrangeButton
            extraClass="serv-details-single-btn"
            onClick={() => this.onCancelClick()}
          >
            {cancel_text}
          </OrangeButton>
        </div>
      </Modal>
    )
  }
}

export default ServiceDetailsUpdate
